<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { Game } from "@/types";

const props = withDefaults(
	defineProps<{
		category?: string;
		title?: string;
		icon?: string;
		games: Game[];
		rowsPerSlide?: number;
		showViewAll?: boolean;
		isAdminIcon?: boolean;
	}>(),
	{ rowsPerSlide: 1, showViewAll: true }
);

const { t } = useT();
const { recentWinners } = useRecentGamesWinners();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark" });
const localPath = useLocalePath();

const sliderRef = ref();
const isDisabledNextMove = ref(false);

const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto", spacing: 0 },
	animationEnded(slider) {
		isDisabledNextMove.value = (slider.track.details.slides.at(-1)?.portion ?? 0) >= 0.95;
	}
};

const preparedGames = computed(() =>
	props.games.reduce<Array<Array<Game>>>((acc, item, index) => {
		const chunkIndex = Math.floor(index / props.rowsPerSlide);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, [])
);

const handleNavigateToGames = () => {
	if (!props.category) {
		return;
	}
	navigateTo(localPath(`/issues/${props.category}/`));
};
const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

watch(
	() => props.games.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const isSvg = (icon: string): boolean => icon.trim().startsWith("<svg");
</script>
<template>
	<section>
		<AText
			v-if="title"
			:data-tid="`slider-title-${getTitle(title)}`"
			class="title text-cannes"
			variant="tampa"
			as="h3"
			:modifiers="['bold']"
		>
			<span v-if="isAdminIcon && isSvg(icon)" class="nuxt-icon"><i v-html="icon" /></span>
			<NuxtImg
				v-else-if="isAdminIcon"
				:src="`${baseImageUrl}${icon}`"
				width="32"
				height="32"
				format="avif"
				loading="lazy"
				alt="category"
				class="icon"
			/>
			<NuxtIcon v-else :name="icon" filled />
			<span>{{ title }}</span>
		</AText>

		<ASlider ref="sliderRef" :options="sliderOptions">
			<template v-if="rowsPerSlide === 1">
				<MGame
					v-for="(game, index) in games"
					:key="game.id"
					:game="game"
					:winner-data="recentWinners.get(game.id ?? 0)"
					:data-tid="getDataTid(index)"
					class="keen-slider__slide"
					@toggle-favorite="handleToggleToFavoriteClick"
				/>
			</template>
			<template v-else>
				<div
					v-for="(item, index) in preparedGames"
					:key="`${category}-${index}`"
					class="keen-slider__slide slide-column"
				>
					<MGame
						v-for="game in item"
						:key="game.id"
						:game="game"
						:data-tid="getDataTid(index)"
						:winner-data="recentWinners.get(game.id ?? 0)"
						@toggle-favorite="handleToggleToFavoriteClick"
					/>
				</div>
			</template>

			<template #dots="{ slider, activeIndex }">
				<MSliderNavigation
					v-if="games.length"
					:location="category || 'games'"
					:location-tid="title || 'games'"
					:is-disabled-prev="activeIndex === 0"
					:is-disabled-next="isDisabledNextMove"
					:view-all-label="showViewAll ? t('view all') : undefined"
					@click-prev="slider?.prev()"
					@click-next="slider?.next()"
					@click-view-all="handleNavigateToGames"
				/>
			</template>
		</ASlider>
	</section>
</template>
<style lang="scss" scoped>
section {
	padding: gutter(3) 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
	}

	&:deep(.keen-slider) {
		padding-top: gutter(3);

		@include media-breakpoint-down(md) {
			padding-top: gutter(2.5);
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: 160px;

		&:not(:last-of-type) {
			width: calc(160px + 12px);
			padding-right: gutter(1.5);

			@include media-breakpoint-down(md) {
				width: calc(160px + 8px);
				padding-right: gutter(1);
			}
		}

		&.slide-column {
			display: flex;
			flex-direction: column;
			gap: gutter(1.5);

			@include media-breakpoint-down(md) {
				gap: gutter(1);
			}
		}
	}
}
.title {
	display: flex;
	align-items: center;
	gap: gutter(1);
}

.navigation-holder {
	position: absolute;
	right: 0;
	top: 24px;

	@include media-breakpoint-down(md) {
		top: 20px;
	}
}
</style>
